import React, { Fragment, cloneElement, Children, useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Loading from '../components/loading';
import { InView } from 'react-intersection-observer';
import SEO from '../components/seo';
import Contact from '../sections/Contact';
import ProjectsList from '../sections/Projects';
import ProjectTemplate from '../projects';
import { ProjectDispatchContext } from '../context/ProjectsContextProvider';

const shuffleAndSlice = (array, limit = 7) => {
	return array.sort(() => Math.random() - 0.5).filter((e, i) => i < limit);
};
// wrap all sections with inView component - watch if any sections are out of viewport
const Sections = ({ children, width, id }) => {
	const dispatch = useContext(ProjectDispatchContext);
	useEffect(() => {
		// console.clear();
		console.log('project ID: ', id);
		dispatch({
			type: 'GET_PROJECT_BY_ID',
			payload: id
		});
	}, []);
	return (
		<div className={`g-grid__${width}`}>
			{Children.map(children, (child, index) => (
				<InView key={index} threshold={0.45}>
					{({ inView, ref, entry }) => cloneElement(child, { ref, isVisible: inView, id })}
				</InView>
			))}
		</div>
	);
};

const Projects = ({ data: { selectedProject, otherProjects } }) => {
	const { slug: selectedProjectId } = selectedProject.frontmatter;
	console.log(otherProjects);
	otherProjects = shuffleAndSlice(otherProjects.edges);

	return (
		<Layout>
			<Helmet bodyAttributes={{ id: 'project' }} />
			<SEO title={`Project - ${selectedProjectId}`} />
			<Sections width="full" id={selectedProjectId}>
				<ProjectTemplate />
				<ProjectsList otherProjects={otherProjects} />
				{/* <Contact/> */}
			</Sections>
		</Layout>
	);
};

export default Projects;

export const pageQuery = graphql`
	query ProjectPage($slug: String) {
		selectedProject: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
			frontmatter {
				banner
				buttonTitle
				client
				colors
				logo
				project
				slug
				technology
				thumbnail
				title
				year
			}
			html
			id
		}
		otherProjects: allMarkdownRemark(filter: { frontmatter: { slug: { ne: $slug } } }) {
			edges {
				node {
					id
					frontmatter {
						slug
						project
						logo
						colors
						client
						buttonTitle
						banner
						technology
						thumbnail
						title
						year
						order
					}
				}
			}
		}
	}
`;
