import React, { forwardRef, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Sections from '../sections/index';
import Template from './Template';
import { ProjectStateContext } from '../context/ProjectsContextProvider';
import ArrowIcon from './arrow-left.inline.svg';

const rearrangeProjects = (id, projects) => {
	const current = projects.findIndex(({ node: { frontmatter: { slug } } }) => slug === id);
	if (current <= 0) return projects.map((p) => ({ ...p.node.frontmatter, description: p.node.html }));

	const preArray = projects.slice(0, current);
	const postArray = projects.slice(current);
	const newProjects = [ ...postArray, ...preArray ];

	return newProjects.map((p) => ({ ...p.node.frontmatter, description: p.node.html }));
};

const Projects = forwardRef(({ isVisible, reverse, id }, ref) => {
	const state = useContext(ProjectStateContext);
	const isIDMatched = (id) => state.projects[0].id === id;
	const [ showProject, setShowProject ] = useState(false);

	useEffect(
		() => {
			setShowProject(true);
		},
		[ id ]
	);

	// id === slug of current project
	// get all projects from graphql
	// rearrange project with current project as the 1st
	const { allMarkdownRemark: { edges: projects } } = useStaticQuery(graphql`
		query MyQuery {
			allMarkdownRemark(sort: { fields: frontmatter___order, order: ASC }) {
				edges {
					node {
						frontmatter {
							slug
							project
							logo
							colors
							client
							buttonTitle
							banner
							technology
							thumbnail
							title
							year
							order
							url
						}
						html
					}
				}
			}
		}
	`);

	const orderedProjects = rearrangeProjects(id, projects);

	return (
		<>
		<div className="projects-page page" ref={ref}>
			{/* <Sections start={0} loop={true} reverse={reverse} isVisible={isVisible} projects={state.projects}> */}
			{/* {(isIDMatched(id) || showProject) && state.projects.filter((p, i) => i < 5).map((project) => <Template key={project.id} />)} */}
			<Sections start={0} loop={true} reverse={reverse} isVisible={isVisible} projects={orderedProjects}>
				{orderedProjects.filter((p, i) => i < 5).map(({ slug }) => <Template key={slug} />)}
			</Sections>
			
		</div>
		{/* <div className="projects-page__back">
			<ArrowIcon/><span>BACK</span>
		</div> */}
		</>
	);
});

export default Projects;
